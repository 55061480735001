(function () {
    (new WOW().init());
    var desktopMenu = $('#portMenu'), textSlide = $('div.text-slide'), dots = $('#home-slider').find('.slick-dots'), closeMenu = $("#closeMenu"), hamburger = $('div#Hamburguer');
    hamburger.on('click', function () {
        console.log('clicking');
        desktopMenu.addClass('show-menu');
        //textSlide.slideToggle();
    });
    closeMenu.click(function (e) {
        e.preventDefault();
        desktopMenu.removeClass('show-menu');
    });
    if ($(window).width() < 768) {
        $("#theMenu").addClass('d-flex flex-column align-items-center');
    }
})();
